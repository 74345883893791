import "./footer.css";
import { Link } from "react-router-dom";

import {
  FaFacebookSquare,
  FaLinkedin,
  FaInstagramSquare,
  FaWikipediaW,
  FaYoutube,
} from "react-icons/fa";

import { FaSquareXTwitter } from "react-icons/fa6";

const Footer = () => {
  const socialMediaLinks = {
    facebook: "https://www.facebook.com/SohanRoy",
    linkedin: "https://www.linkedin.com/in/sohanroy",
    instagram: "https://www.instagram.com/sohan_roy_official",
    twitter: "https://www.twitter.com/sohanroy",
    wikipedia: "https://en.wikipedia.org/wiki/Sohan_Roy",
    youtube: "https://www.youtube.com/c/SohanRoySK",
    esol: "https://www.ariesesolutions.com/",
  };

  const handleClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div className="footer">
      <p>
        All Rights Reserved.{" "}
        <span>
          <button
            className="esol-btn"
            onClick={() => handleClick(socialMediaLinks.esol)}
          >
            Aries e-Solutions
          </button>
        </span>
        <br />
        Aries Telecasting Pvt. Ltd
      </p>
      <div className="footer-contact-container">
        <Link to="tel:+919539000518">+91 9539000518 (Lakshmi Atul)</Link>
        <Link to="mailto:+919539000518">ceo@ariesgroup.ae </Link>
      </div>
      <div>
        <FaFacebookSquare
          className="social-icons"
          onClick={() => handleClick(socialMediaLinks.facebook)}
        />
        <FaLinkedin
          className="social-icons"
          onClick={() => handleClick(socialMediaLinks.linkedin)}
        />
        <FaInstagramSquare
          className="social-icons"
          onClick={() => handleClick(socialMediaLinks.instagram)}
        />
        <FaSquareXTwitter
          className="social-icons"
          onClick={() => handleClick(socialMediaLinks.twitter)}
        />
        <FaWikipediaW
          className="wiki-icon"
          onClick={() => handleClick(socialMediaLinks.wikipedia)}
        />
        <FaYoutube
          className="yt-icon"
          onClick={() => handleClick(socialMediaLinks.youtube)}
        />
      </div>
    </div>
  );
};

export default Footer;
